import React from 'react';
import {Link} from 'react-router-dom';

class Bookshelf extends React.Component{
    render(){
    return (
        <div className='container mt-5 text-start align-start'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Bookshelf</li>
                </ol>
            </nav>
            <div className='content text-start align-start'>
            <h1>Bookshelf</h1>
        
            <p>Listing down all the books that I read, <span className='highlight'>highlighted</span> ones are must read.*</p>
            <h6 className='mt-5'>Currently Reading...</h6>
            <ul>
                 <li>
                 The Dark Forest - Liu Cixin
                  </li>
                
                 <li>
                Work the System - Sam Carpenter
                  </li>


                  <li>
                  Zen and the Art of Motorcycle Maintenance - Robert M. Pirsig
                  </li>
                 <li>
                    The Book Of Chocolate Saints - Jeet Thayil
                </li>
                                 <li>
                   Lolita - Vladimir Nabokov
                </li>
{/*                  <li>
                    Beautiful You - Chuck Palahniuk 
                </li>
                <li> Napolean - Adam Zamoyski 
        </li> */}
            </ul>
            <h6>Books</h6>
            <ul>
                            <li className='highlight'>Shantaram - Gregory David Roberts </li>
<li>1984 - George Orwell</li>
                  
                <li> Notes from Underground - Fyodor Dostoevsky </li>
                <li className='highlight'>Autobiography of a yogi - Paramhansa Yogananda</li>
                <li>My experiments with truth - M.K. Gandhi</li>
                <li>Dark Matter -Blake Crouch</li>
                <li className='highlight'>Animal Farm - George Orwell </li>
             
                <li>Think and grow rich - Napolean Hill</li>
                                    <li>
                    The White Tiger - Aravinda Adiga
                </li>
        
        
                <li>
                    Kucch Ishq kiya kucch kaam kiya - Piyush Mishra
                </li>
                <li className='highlight'>
                    Catching the Big Fish - David Lynch
                </li>
                <li>Inner Engineering - Sadhguru</li>
                <li>Pride & Prejudice - Jane Austen</li>
                                    <li className='highlight'>Letter to Young Poet - Rainer Maria Rilke </li>

                <li>Be here now - Baba Ram Das</li>
                <li className='highlight'>The Hitchhiker's Guide to Galaxy - Douglas Adams</li>
                <li>Life's amazing secrets - Gaur Gopal Das</li>
                <li>Autobiography of Ben Franklin</li>
                <li>
                Call Me By Your Name - André Aciman
                </li>
                <li className='highlight'>Metamorphosis - Franz Kafka</li>
                <li>Immortal talks 1 - Shunya</li>
                <li>That will <em className='i'>never</em> work - Marc Randolph</li>
                <li>Poor Economics - Esther Duflo & Abhijit Banarjee</li>
                <li className='highlight'>Fault in our stars - John Green</li>
                <li>Looking for Alaska - John Green</li>
                <li>Turtles all the way down - John Green</li>
                <li>The Slap - Christos Tsiolkas</li>
                <li>Zero to One - Peter Thiel</li>
                <li>Monk who sold his Ferrari - Robin Sharma</li>
                <li>21 lessons for 21st Century - Yuval Noah Harari</li>
                <li>Sapiens - Yuval Noah Harari</li>
                <li className='highlight'>Man's search for meaning - Victor Frankl</li>
                <li>The Lost Symbol - Dan Brown</li>
                <li>We are displaced - Malala Yousafzai</li>
                <li>The Google Story - David Vice</li>
                <li>13 Reasons Why - Jay Asher</li>
                <li>Atomic Habits - James Clear</li>
                <li className='highlight'>The Selfish Gene - Richard Dawkins</li>
                <li>Brief Answers to the Big Questions - Stephen Hawkings</li>
                <li>Harry Potter and sorcerer's stone - J.K. Rowling</li>
                <li>Harry Potter and chamber of secrets- J.K. Rowling</li>
                <li>Harry Potter and the goblet of fire - J.K. Rowling</li>
                <li>Harry Potter and Prisoner of Azkaban - J.K. Rowling</li>
                <li className='highlight'>Harry Potter and the Deathly Hallows - J.K. Rowling</li>
                <li>Bobby Fischer teaches Chess - Bobby Fischer</li>
                <li>The virtue of selfishness - Ayn Rand</li>
                <li className='highlight'>The Silent Patient - Alex Michaelides</li>
                <li>For the love of physics - Walter Lewin</li>
                <li>The Psychology of Selling - Brian Tracy</li>
                <li className='highlight'>The Third Door - Alex Banayan</li>
                <li>12 Rules for Life - Jordan Peterson</li>
                <li>The Alchemist </li>
                       <li>Deep Work - Cal Newport</li>
                <li>Five Point Someone</li>
                <li>3 Mistakes of my life</li>
                <li>Diary of a Wimpy Kid - The Third Wheel</li>
                <li>The Theory of everything - Stephen Hawkings</li>
                <li className='highlight'>Steve Jobs - Walter Isaacson</li>
                <li>How to win friends & influence people - Dale Carnegie</li>
                <li>How to top worrying and start living - Dale Carnegie</li>
                <li className='highlight'>Elon Musk - Ashlee Vance</li>
                <li>Who will cry when you die - Robin Sharma</li>
                <li>The Mastery Manual - Robin Sharma</li>       
                <li>Rich Dad Poor Dad</li>          
                <li>The Secret</li> 
                <li>The Power of Subconcious Mind</li>
                <li>A Short History of Nearly Everything - Bill Bryson</li>
                <li>Thinking Fast & Slow - Daniel Kahneman</li>
                <li>Traction - Gabriel Weinberg and Justin Mares</li>
                
                <li>
                    Homo Deus - Yuval Hoah Harari
                </li><li>
                    Tools of Titans - Tim Feriss
                </li>
                <li>
                    Ego is the Enemy - Ryan Holiday
                </li>
                <li className='highlight'>
                    Fooled by Randomness - NN Taleb
                </li>
                <li>
                    Range - David Epstein
                </li>
                <li>
                    Kafka on the shore - Haruki Murakami
                </li>
    
                <li>
                Hippie - Paulo Coelho
                </li>
                <li className='highlight'>
                    Debt: The first 5000 years - David Graeber
                </li>
                <li>
                    Ready Player One -  Ernest Cline
                </li>
                <li>
                    The Firm: Story of McKinsey -  Duff McDonald 
                </li>
   
                <li>Range - David Epstein</li>
                <li>Norwegian Wood - Haruki Murakami</li>
                <li>Rework - Jason Fried</li>
                <li>Straight Line Selling System - Jordan Belfort</li>
                <li>Black Swan - NN Taleb</li>
                <li className='highlight'>Anti-Fragile - NN Taleb</li>
                <li>The Prophet</li>
                
                <li className='highlight'>
                    Madhushala - HarivanshRai Bachhan
                </li>
                <li>Principles - Ray Dalio</li>
                <li>The Mom Test - How to talk to customers</li>
                <li>How to Sell Like a Champion</li>
        
                <li> Narcopolis - Jeet Thayil
        </li>
        
                <li>
                    Working Backwards (Insights, Stories, and Secret from Amazon) - Colin Bryar & Bill Carr
                </li>
        

                
                <li>
                    How Life Imitates Chess - Garry Kasparov 
                </li>
        <li>
                    What I Talk About When I Talk About Running - Haruki Murakami
                </li>
                
        <li>
                    Cinema Speculation - Quentin Tarantino
                </li>
                        <li>
                    Once Upon A Time in...Hollywood - Quentin Tarantino
                </li>
                        <li>
                    The Way to Wealth - Benjamin Franklin
                </li>
                
                        <li>
                    The Thousand Splendid Sun - Khaled Hosseini 
              
        </li>
        <li> Circles of Hell - Dante
        </li>
        <li> The Eve of St. Agnes - John Keats </li>
                 <li>
                    Freakonomics - Steven Levvitt
                </li>
                <li>
                    SuperFreakonomics - Steven Levvitt
                </li>
             
             
                 <li className='highlight'>
                    Hard Things About Hard Things - Ben Horowitz
                </li>   
                     <li>
                        My Brilliant Friend - Elena Ferrante
                </li>
                <li>
                        To Pixar and Beyond: My Unlikely Journey with Steve Jobs - Lawrence Levy
                </li>
                  <li>
                    IQ84 Trilogy - Haruki Murakami
                </li>
                 <li>
                    Cat's Cradle - Kurt Vonnegut
                </li>
                
                 <li className='highlight' >
                    Low - Jeet Thayil
                </li>
                
                 <li>
                    Get To The Top - Suhel Seth
                </li>
                <li>
                The Aosawa Murders - Riku Onda
                </li>
                                <li className='highlight' >
                Stoner - John Williams
                </li>
                             <li>
                After Dark - Murakami
                </li>
                <li>
                    Rant - Chuck Palahniuk 
                </li>
                <li>
                    The Pleasures of the Damned - Charles Bukowski 
                </li>
                   <li>
       The Bed of Procrustes - Nassim Nicholas Taleb
                </li>
                <li>
                    Never Enough - Andrew Wilkinson 
                </li>
                 <li>
                    The Three-Body Problem - Liu Cixin
                </li>
                <li className='highlight'>
                    The Vegetarian - Han Kang

                </li>
                  <li>
                Widow Basquiat: A Memoir - Jennifer Clement 
                </li>
                    <li>
                Consider This: Moments in My Writing Life after Which Everything Was Different -  Chuck Palahniuk                </li>
        <br />
    
                *These are the books that I have read (closest or) to the completion. Rest and most books, I put down.
            </ul>
        </div>
        </div>
    )
}
}

export default Bookshelf;
